import React, { Component } from "react";

import Image1 from "../../../images/Ariesimgs/img/team/Tim2.jpg";
import Image2 from "../../../images/logos/Dark Gray Orange Minimalist Contractor Accreton Logo/2.png";

class About1 extends Component {
  render() {
    return (
      <>
        <div className="row" id="about">
          <div className="col-md-6">
            <div className="about-img-box3">
              <div className="img1">
                <img src={Image1} alt="" />
              </div>
              <div className="img2">
                <img src={Image2} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="heading-bx m-b20">
              <h6 className="title-ext m-b0">Tim Shannon</h6>
              <h2 className="title-head m-b0">Aries Builders, Inc.</h2>
              <div className="ttr-separator sepimg"></div>
              <h5 className="title-text fw4 m-t5 m-b0">
                Born out-of-necessity, Aries Builders was formed in 2014, by Tim
                Shannon. At the time, Tim, was semi-retired, only taking-on
                custom home projects; Local builders were experiencing a
                scarcity of quality Framers and relentlessly called upon Tim to
                solicit his Framing expertise. The rest is history.
              </h5>
              <p className="head-px2">
                Commencing with 60 units in Berkeley, followed by 35 units in
                Cupertino, Aries Builders was back in full force, averaging 300
                units per year, encompassing all types of construction. Aries
                Builders excels in difficult projects requiring intense planning
                & execution to complete, thus insuring timely installation
                without compromising quality. “The Difference Is Obvious”
              </p>
            </div>
            <a href="#faqs" className="btn">
              Our Process
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default About1;
