import React, { Component } from "react";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";

// Images
import Image1 from "../../../images/Ariesimgs/img/2020-08-14 10.50.24 - Copy.jpg";
import Image2 from "../../../images/Ariesimgs/img/2020-05-22 15.42.31.jpg";

class Slider1 extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      lazyLoad: true,
      autoplaySpeed: 500,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 2,
    };

    const aniDelay = 4000;

    return (
      <>
        <Slider {...settings} className="tt-slider slider-one slider-sp0">
          <div className="slider-item">
            <div className="slider-thumb">
              <img src={Image1} alt="" />
            </div>
            <div className="slider-content text-white text-center">
              <div className="container">
                <div className="content-inner">
                  <h6 className="sub-title">Aries Builders, Inc</h6>

                  <Fade cascade collapse duration={aniDelay} mirror={true}>
                    <h2 className="title">WE PROVIDE THE BEST </h2>
                  </Fade>
                  <Fade cascade collapse duration={aniDelay} mirror={true}>
                    <h2 style={{ fontWeight: "bold" }}>FRAMING SERVICES</h2>
                  </Fade>
                  <Fade cascade collapse duration={aniDelay} mirror={true}>
                    <h2>In the Bay Area</h2>
                  </Fade>

                  <a className="btn m-lr10" href="#footer-1">
                    Get In Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item">
            <div className="slider-thumb">
              <img src={Image2} alt="" />
            </div>
            <div className="slider-content text-white text-center">
              <div className="container">
                <div className="content-inner">
                  <Fade cascade collapse duration={aniDelay} mirror={true}>
                    <h2 className="title">WE PROVIDE THE BEST </h2>
                  </Fade>
                  <Fade cascade collapse duration={aniDelay} mirror={true}>
                    <h2 style={{ fontWeight: "bold" }}>FRAMING SERVICES</h2>
                  </Fade>
                  <Fade cascade collapse duration={aniDelay} mirror={true}>
                    <h2>In the Bay Area</h2>
                  </Fade>

                  <a className="btn m-lr10" href="#footer-1">
                    Get In Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </>
    );
  }
}

export default Slider1;
