import React, { Suspense } from "react";
import Preloader from "./markup/elements/Preloader";

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import "react-modal-video/css/modal-video.min.css";

import "swiper/swiper-bundle.min.css";

// Fonts
import "./vendors/fontawesome/css/font-awesome.min.css";
import "./vendors/flaticon/flaticon.css";
import "./vendors/flaticon/light/flaticon1.css";
import "./vendors/line-awesome/css/line-awesome.css";
import "./vendors/themify/themify-icons.css";

// Stylesheet
import "./css/typography.css";
import "./css/shortcodes/shortcodes.css";
import "./css/style.css";
import "./css/color/color-1.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
/*
class App extends React.Component {

	state = {
		loading: true
	};

	componentDidMount() {
		this.demoAsyncCall().then(() => this.setState({ loading: false }));
	}

	demoAsyncCall = () => {
		return new Promise((resolve) => setTimeout(() => resolve(), 3000));
	}

	hashLinkScroll = () => {
		const { hash } = window.location;
		if (hash !== '') {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) element.scrollIntoView();
			}, 0);
		}
	}

	render() {
		return (
			<Router onUpdate={this.hashLinkScroll}>
				<React.Fragment>
					{this.state.loading ? <Preloader /> : ''}
					<Markup />
				</React.Fragment>
			</Router>
		);
	}
}

export default App;

*/

import { BrowserRouter, Route, Switch } from "react-router-dom";

// Elements
import BackToTop from "../src/markup/elements/back-top";

import Index from "../src/markup/pages/index";
import Error404 from "../src/markup/pages/error-404";
import ProjectsCarousel4 from "../src/markup/pages/projects-carousel-4";

class App extends React.Component {
  // state = {
  //   loading: true,
  // };

  // componentDidMount() {
  //   this.demoAsyncCall().then(() => this.setState({ loading: false }));
  // }

  // demoAsyncCall = () => {
  //   return new Promise((resolve) => setTimeout(() => resolve(), 6000));
  // };

  // hashLinkScroll = () => {
  //   const { hash } = window.location;
  //   if (hash !== "") {
  //     setTimeout(() => {
  //       const id = hash.replace("#", "");
  //       const element = document.getElementById(id);
  //       if (element) element.scrollIntoView();
  //     }, 0);
  //   }
  // };

  render() {
    return (
      <>
        <BrowserRouter onUpdate={this.hashLinkScroll}>
          <Switch>
            {/* {this.state.loading ? <Preloader /> : ""} */}
            <Suspense fallback={<Preloader />}>
              <Route path="/" exact component={Index} />
              <Route path="/error-404" component={Error404} />
              <Route
                path="/projects-carousel-4"
                component={ProjectsCarousel4}
              />
            </Suspense>
          </Switch>
        </BrowserRouter>
        <BackToTop />
      </>
    );
  }
}

export default App;

/*
function App() {
	return (
		<div className="App">
			<Markup />
		</div >

	);
}


export default App;

*/
