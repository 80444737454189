import React, { Component } from "react";
import Slider from "react-slick";

// Images
import ServicesPic1 from "../../../images/Ariesimgs/img/1FB73415F850CBAE432BD8E3E8437F6D.jpeg";
// import ServicesPic2 from "../../../images/Ariesimgs/img/1FB73415F850CBAE432BD8E3E8437F6D.jpeg";
import ServicesPic3 from "../../../images/Ariesimgs/14-Metro-West-Outdoor-Theater-Hero-View-R03_1920.jpg";
// import ServicesPic4 from "../../../images/Ariesimgs/img/F2F572428B9BEE138BFC4DA4824FC396.jpg";

class ServiceSlider2 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        <Slider
          {...settings}
          className="slider-sp0 service-gallery-top arrow-none"
        >
          <div className="slider-item">
            <div
              className="service-info"
              style={{ backgroundImage: "url(" + ServicesPic1 + ")" }}
            >
              <div className="container">
                <div className="service-content" data-swiper-parallax-x="500">
                  <h3 className="ttr-tilte">East Bay</h3>
                  <div className="ttr-separator sepimg"></div>
                  <p>
                    Larkey Estates. A four lot sub-division located in Walnut
                    Creek.
                  </p>
                  {/*<Link to="#" className="btn">
                    Read More
	</Link> */}
                  <i className="flaticon-tractor bg-icon"></i>
                </div>
              </div>
            </div>
          </div>
          {/*} <div className="slider-item">
            <div
              className="service-info"
              style={{ backgroundImage: "url(" + ServicesPic2 + ")" }}
            >
              <div className="container">
                <div className="service-content" data-swiper-parallax-x="500">
                  <h3 className="ttr-tilte">East Bay</h3>
                  <div className="ttr-separator sepimg"></div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Risus nullam eget felis eget nunc lobortis mattis
                    aliquam faucibus.
                  </p>
                  <Link to="#" className="btn">
                    Read More
                  </Link>
                  <i className="flaticon-flask bg-icon"></i>
                </div>
              </div>
            </div>
          </div> */}
          <div className="slider-item">
            <div
              className="service-info"
              style={{ backgroundImage: "url(" + ServicesPic3 + ")" }}
            >
              <div className="container">
                <div className="service-content" data-swiper-parallax-x="500">
                  <h3 className="ttr-tilte">South Bay</h3>
                  <div className="ttr-separator sepimg"></div>
                  <p>Toll Brothers - Metro Crossing located in Fremont, CA.</p>
                  {/*<Link to="#" className="btn">
                    Read More
					</Link>*/}
                  <i className="flaticon-trolley bg-icon"></i>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="slider-item">
            <div
              className="service-info"
              style={{ backgroundImage: "url(" + ServicesPic4 + ")" }}
            >
              <div className="container">
                <div className="service-content" data-swiper-parallax-x="500">
                  <h3 className="ttr-tilte">East Bay</h3>
                  <div className="ttr-separator sepimg"></div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Risus nullam eget felis eget nunc lobortis mattis
                    aliquam faucibus.
                  </p>
                  {/*<Link to="#" className="btn">
                    Read More
					</Link>
                  <i className="flaticon-mask bg-icon"></i>
                </div>
              </div>
            </div>
				</div> */}
        </Slider>
      </>
    );
  }
}

export default ServiceSlider2;
