import React from "react";
import Zoom from "react-reveal/Zoom";

class Preloader extends React.Component {
  render() {
    return (
      <div className="preloader-Main">
        <Zoom top cascade duration={2900}>
          <h4 className={"h4-loader"}>Loading....</h4>
        </Zoom>
      </div>
    );
  }
}

export default Preloader;
