import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import PortImg15 from "../../../images/Ariesimgs/img/12C777594F093AA57C94486FA1677AB5.jpeg"
import PortImg16 from "../../../images/Ariesimgs/img/52389740B9556104BEC0D8196E3C2C33.jpeg"
import PortImg17 from "../../../images/Ariesimgs/img/3C7A4BAC62E8390B934316BB143536E5.jpeg"
import PortImg18 from "../../../images/Ariesimgs/img/2020-05-22 15.43.15.jpg"

class ProjectSlider6 extends Component{
	
	render(){
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				<Slider {...settings} className="portfolio-carousel-4 style-4 slider-sp7 owl-carousel owl-btn-1 owl-btn-center-lr arrow-none"> 
					
					<div className="slider-item">
						<div className="portfolio-box style-3 m-b0">
							<div className="portfolio-media">
								<img src={PortImg18} alt=""/>
							</div>
							<div className="portfolio-info">
								<h4 className="title"><Link to="projects-single-4">Danville, CA</Link></h4>
								<span className="exe-title"></span>	
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="portfolio-box style-3 m-b0">
							<div className="portfolio-media">
								<img src={PortImg16} alt=""/>
							</div>
							<div className="portfolio-info">
								<h4 className="title"><Link to="projects-single-4">Walnut Creek, CA</Link></h4>
								<span className="exe-title"></span>	
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="portfolio-box style-3 m-b0">
							<div className="portfolio-media">
								<img src={PortImg17} alt=""/>
							</div>
							<div className="portfolio-info">
								<h4 className="title"><Link to="projects-single-4">Walnut Creek, CA</Link></h4>
								<span className="exe-title"></span>	
							</div>
						</div>
					</div>
					<div className="slider-item">
						<div className="portfolio-box style-3 m-b0">
							<div className="portfolio-media">
								<img src={PortImg15} alt=""/>
							</div>
							<div className="portfolio-info">
								<h4 className="title"><Link to="projects-single-4">Walnut Creek, CA</Link></h4>
								<span className="exe-title"></span>	
							</div>
						</div>
					</div>
				</Slider>
			</>
		);
	}
}

export default ProjectSlider6;
