import React, { Component } from "react";

import LogoWhite from "../../../images/logos/7.png";
import IconImg1 from "../../../images/icon/contact/icon1.png";
import IconImg2 from "../../../images/icon/contact/icon2.png";
import IconImg3 from "../../../images/icon/contact/icon3.png";

class Footer1 extends Component {
  render() {
    return (
      <>
        {/* <!-- Footer ==== --> */}
        <footer className="footer-style2" id="footer-1">
          <div className="footer-top bt0">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-6 col-sm-6 l-md-b36">
                  <div className="feature-container left footer-info-bx">
                    <div className="feature-lg text-white">
                      <span className="icon-cell p-12">
                        <img src={IconImg3} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-tilte">Address</h5>
                      <p>Danville, CA</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-md-b36">
                  <div className="feature-container left footer-info-bx">
                    <div className="feature-lg text-white">
                      <span className="icon-cell p-12">
                        <img src={IconImg1} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-tilte">Contact Number</h5>
                      <p>+1 (925) 250-3803</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 m-sm-b36">
                  <div className="feature-container left footer-info-bx">
                    <div className="feature-lg text-white">
                      <span className="icon-cell p-12">
                        <img src={IconImg2} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-tilte">Email Address</h5>
                      <p>Timshannon7@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
                  <p className="m-b0">
                    Copyright © 2022
                    <img
                      src={LogoWhite}
                      className="footer-b-logo m-lr5"
                      width="50"
                      alt=""
                    />
                    All Rights Reserved.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-right">
                  <ul className="link-inline">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/#about">About Us</a>
                    </li>
                    <li>
                      <a href="/#projects">Projects</a>
                    </li>
                    <li>
                      <a href="/#faqs">Process</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer1;
