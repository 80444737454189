import React, { Component } from "react";
import Slider from "react-slick";

class ServiceSlider1 extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <Slider {...settings} className="slider-sp0 arrow-none slider-process">
          <div className="slider-item">
            <div className="process-box">
              <h5 className="title">
                <i className="ti-world"></i> Who We Are
              </h5>
              <p></p>
              <a href="/#about" className="process-btn">
                About Us<i className="ti-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="slider-item">
            <div className="process-box">
              <h5 className="title">
                <i className="ti-shield"></i> Our Process
              </h5>
              <p></p>
              <a href="/#faqs" className="process-btn">
                View our Process <i className="ti-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="slider-item">
            <div className="process-box">
              <h5 className="title">
                <i className="ti-panel"></i> Contact
              </h5>
              <p></p>
              <a href="/#footer-1" className="process-btn">
                Contact me today <i className="ti-arrow-right"></i>
              </a>
            </div>
          </div>
        </Slider>
      </>
    );
  }
}

export default ServiceSlider1;
