import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

// Layout
import Header1 from "../layout/header/header1";
import Footer1 from "../layout/footer/footer1";

// Elements
import Slider from "../elements/slider/slider1";
import ServiceSlider1 from "../elements/services/service-slider-1";
import ServiceSlider2 from "../elements/services/service-slider-2";
import About from "../elements/about/about1";
import NumberBox from "../elements/number-box/number-box";

// Images
import ServicesPic1 from "../../images/Ariesimgs/img/52389740B9556104BEC0D8196E3C2C33.jpeg";
import ServicesPic2 from "../../images/Ariesimgs/img/343437761AA94FE86362E814E89AC64F.jpeg";

class Index extends Component {
  const;

  render() {
    return (
      <>
        <Header1 />

        <div className="page-content bg-white">
          <Suspense fallback={<h1>Loading...</h1>}>
            <Slider />
          </Suspense>

          {/* <!-- Main Slider --> */}

          <div className="content-block" id="content-area">
            <Suspense fallback={<h1>Loading...</h1>}>
              <div className="section-area process-area" id="home">
                <div className="container">
                  <Link to="projects-carousel-4" className="project-btn">
                    <i className="icon ti-home"></i> View Our Projects{" "}
                    <i className="ti-arrow-right right-icon"></i>
                  </Link>
                  <ServiceSlider1 />
                </div>
              </div>
            </Suspense>

            <div className="section-area section-sp3 bg-white">
              <div className="container">
                <About />
              </div>
            </div>

            <div className="section-area section-sp1 bg-white">
              <div className="container">
                <NumberBox />
              </div>
            </div>

            <div className="section-area bg-white" id="projects">
              <ServiceSlider2 />
            </div>

            <div className="section-area bg-white section-sp2" id="faqs">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="heading-bx m-b20 text-center  text-lg-left m-md-b30">
                      <h6 className="title-ext m-b0">Our Process</h6>
                      <h2 className="title-head m-b0">
                        Aries Process for success!
                      </h2>
                      <div className="ttr-separator sepimg"></div>
                      <p className="head-px2">
                        We want to ensure your project is done with care from
                        start to finish.
                      </p>
                      <Fade left cascade>
                        <Link to="projects-carousel-4" className="btn">
                          View All Projects
                        </Link>
                      </Fade>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <Fade bottom cascade>
                      <div className="service-box text-center m-xs-b30">
                        <div className="service-media">
                          <img src={ServicesPic1} alt="" />
                          <div className="service-inner">
                            <Link
                              to="projects-carousel-4"
                              className="btn white"
                            >
                              View All Projects
                            </Link>
                          </div>
                        </div>
                        <div className="service-info">
                          <h4 className="title">Quality Estimation</h4>
                          <p> </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <Fade bottom cascade>
                      <div className="service-box text-center">
                        <div className="service-media">
                          <img src={ServicesPic2} alt="" />
                          <div className="service-inner">
                            <Link
                              to="projects-carousel-4"
                              className="btn white"
                            >
                              View Projects
                            </Link>
                          </div>
                        </div>
                        <div className="service-info">
                          <h4 className="title">Pre-Templated Framing</h4>
                          <p> </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer1 />
      </>
    );
  }
}

export default Index;
