import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";

class NumberBox extends Component {
  render() {
    return (
      <>
        <div className="row" id="value">
          <Zoom casecade>
            <div className="col-md-4">
              <div className="number-box" data-name="01">
                <div className="number-icon">01</div>
                <h5 className="title">Superior Leadership</h5>
                <p>35+ years of management and construction experience.</p>
                {/*} <Link to="#" className="btn-link">
                  Read More
	</Link> */}
              </div>
            </div>
          </Zoom>
          <Zoom casecade>
            <div className="col-md-4">
              <div className="number-box" data-name="02">
                <div className="number-icon">02</div>
                <h5 className="title">Process and Quality </h5>
                <p>
                  Years of experience to fine tune our process to produce the
                  best quality and experience.
                </p>
                {/*} <Link to="#" className="btn-link">
                  Read More
	</Link> */}
              </div>
            </div>
          </Zoom>
          <Zoom casecade>
            <div className="col-md-4">
              <div className="number-box" data-name="03">
                <div className="number-icon">03</div>
                <h5 className="title">The Aries Difference</h5>
                <p>
                  The difference between Aries and other sub-contractors will be
                  obvious!
                </p>
                {/*} <Link to="#" className="btn-link">
                  Read More
	</Link> */}
              </div>
            </div>
          </Zoom>
        </div>
      </>
    );
  }
}

export default NumberBox;
