import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
import Logo from "../../../images/logos/Aries Builders Inc-copy.png";

//Defined

// const altAries = "Aries Builders Inc";

class Header1 extends Component {
  componentDidMount() {
    // Mobile Menu sidebar function
    var btn = document.querySelector(".menuicon");
    var nav = document.querySelector(".menu-links");

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener("click", toggleFunc);

    // Mobile Submenu open close function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }

  render() {
    return (
      <>
        {/* <!-- Header Top ==== --> */}
        <header className="header rs-nav">
          <div className="top-bar">
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="topbar-left">
                  <ul>
                    <li>
                      <Link to="#footer">
                        <i className="la la-phone"></i>Call Us 1+(925) 250-3803
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="las la-envelope-open"></i>
                        Timshannon7@gmail.com
                      </Link>
                    </li>
                  </ul>
                </div>
                {/*	<div className="topbar-right">
									<ul>
										<li><Link to="company-history">History</Link></li>
										<li><Link to="blog-standard">News</Link></li>
										<li><Link to="contact-1">Contact Us</Link></li>
									</ul>
							</div> */}
              </div>
            </div>
          </div>
          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
              <div className="container clearfix">
                {/* <!-- Header Logo ==== --> */}
                <div className="menu-logo">
                  <Link to="/">
                    <img src={Logo} alt="Aries Builders Inc" />
                  </Link>
                </div>
                {/* <!-- Mobile Nav Button ==== --> */}
                <button
                  className="navbar-toggler collapsed menuicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#menuDropdown"
                  aria-controls="menuDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                {/* <!-- Navigation Menu ==== --> */}
                <div
                  className="menu-links navbar-collapse collapse justify-content-end"
                  id="menuDropdown"
                >
                  <div className="menu-logo">
                    <Link to="/">
                      <img src={Logo} alt="" />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li className="active">
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <a href="/#about">About Us</a>
                    </li>
                    <li>
                      <a href="/#projects">Projects</a>
                    </li>
                    <li>
                      <a href="/#faqs">Process</a>
                    </li>
                  </ul>
                  <div className="nav-social-link">
                    {/*<Link to="#"><i className="fa fa-facebook"></i></Link>
										<Link to="#"><i className="fa fa-google-plus"></i></Link> */}
                    <Link to="https://www.linkedin.com/in/tim-shannon-25944853">
                      <i className="fa fa-linkedin"></i>
                    </Link>
                  </div>
                </div>
                {/* <!-- Navigation Menu END ==== --> */}
              </div>
            </div>
          </Sticky>
          {/* <!-- Search Box ==== --> */}
          <div className="nav-search-bar">
            <form action="#">
              <input
                name="search"
                type="text"
                className="form-control"
                placeholder="Type to search"
              />
              <span>
                <i className="ti-search"></i>
              </span>
            </form>
            <span id="search-remove">
              <i className="ti-close"></i>
            </span>
          </div>
        </header>
        {/* <!-- Header Top END ==== --> */}
      </>
    );
  }
}

export default Header1;
